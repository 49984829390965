/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import Page from "../layouts/page"

import { Link } from "gatsby"

const LegalPage = () => {
  return <Page>
    <div>
      <div className="contain page-body">
        <ul class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><span>Legal</span></li>
        </ul>

        <h1>Legal</h1>

        <ul>
          <li><Link to="/legal/general-terms">General Terms of Service</Link></li>
          <li><Link to="/legal/privacy">Privacy Policy</Link></li>
        </ul>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Legal"
    path="/legal"
  />
)

export default LegalPage
